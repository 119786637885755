import '@/assets/cropper.css'
import { App, Button, Col, Divider, Input, List, Radio, Row, Space, Spin } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useAuthStore } from '../stores/useAuthStore'
import { useMediaStore } from '../stores/useMediaStore'

function ImageCrop() {
  const { notification } = App.useApp()
  //store state
  const userId = useAuthStore((s) => s.userId)
  const website = useAuthStore((s) => s.website)
  const selectedImage = useMediaStore((s) => s.selectedImage)
  const croppedImageList = useMediaStore((s) => s.croppedImageList)
  const fetchCropImageList = useMediaStore((s) => s.fetchCropImageList)
  const startCrop = useMediaStore((s) => s.startCrop)
  const generateHtml = useMediaStore((s) => s.generateHtml)
  //components state
  const [cropResult, setCropResult] = useState('')
  const [aspectRatioName, setAspectRatioName] = useState('750*400')
  const [resizeWidth, setResizeWidth] = useState(750)
  const [resizeHeight, setResizeHeight] = useState(400)
  const [cropLoading, setCropLoading] = useState(false)
  const cropperRef = useRef()

  useEffect(() => {
    fetchCropImageList(userId)
  }, [])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  useEffect(() => {
    const Cropper = window.Cropper
    const options = {
      aspectRatio: 750 / 400,
      viewMode: 2,
      ready: function (e) {},
      cropend: function (e) {},
      crop: function (e) {
        onCrop(e)
      },
    }
    const image = document.getElementById('crop-image')

    if (!cropperRef.current.cropper) {
      cropperRef.current.cropper = new Cropper(image, options)
    }
    return () => {}
  }, [])

  function onCrop(e) {
    const currentRatio = cropperRef.current.cropper.options.aspectRatio
    if (isNaN(currentRatio)) {
      setResizeWidth(parseInt(e.detail.width))
      setResizeHeight(parseInt(e.detail.height))
    }
  }

  function crop() {
    setCropLoading(true)
    const cropData = cropperRef.current.cropper.getData()

    startCrop(cropData, resizeWidth, userId, website)
      .then((result) => {
        const imageHtml = result.imageHtml
        navigator.clipboard.writeText(imageHtml)
        setCropResult(imageHtml)

        notification.success({
          message: '裁剪完成',
          description: '请把代码放到 HTML 里使用',
          placement: 'top',
          duration: 60,
        })
      })
      .catch((error) => {
        notification.error({
          message: '裁剪出错',
          description: error.message,
          placement: 'top',
          duration: 60,
        })
      })
      .finally(() => setCropLoading(false))
  }

  function setupRatio() {
    const resizeWidthInt = parseInt(resizeWidth)
    const resizeHeightInt = parseInt(resizeHeight)
    const ratio = resizeWidthInt / resizeHeightInt
    if (isNaN(ratio) || ratio == 0 || ratio == Infinity) {
      cropperRef.current.cropper.setAspectRatio(0.125)
    } else {
      cropperRef.current.cropper.setAspectRatio(ratio)
    }
  }

  const disableEditRatio = aspectRatioName !== '100*100'

  return (
    <Spin spinning={cropLoading} delay={500}>
      <Row gutter={[16, 16]} justify="start">
        <Col span={18}>
          <div style={{ width: '100%', minHeight: '497px', maxHeight: '497px', textAlign: 'center' }}>
            <img
              ref={cropperRef}
              style={{ maxWidth: '100%', display: 'none' }}
              src={
                'https://p9axztuwd7x8a7.mycht.cn/service-fileServer' +
                selectedImage.PII_Location +
                selectedImage.PII_FileName
              }
              id="crop-image"
              alt="Picture"
            />
          </div>
          <Divider />
          <Space direction="vertical" style={{ width: '100%' }}>
            <Radio.Group
              name="aspectRatioGroup"
              value={aspectRatioName}
              onChange={({ target: { value } }) => {
                let width = 0
                let height = 0
                let ratioValue = 'NaN'
                if (value === 'NaN') {
                  width = parseInt(cropperRef.current.cropper.getData().width)
                  height = parseInt(cropperRef.current.cropper.getData().height)
                } else {
                  const widthAndHeight = value.split('*')
                  width = parseInt(widthAndHeight[0])
                  height = parseInt(widthAndHeight[1])
                  ratioValue = width / height
                }
                cropperRef.current.cropper.setAspectRatio(ratioValue)
                setResizeWidth(width)
                setResizeHeight(height)
                setAspectRatioName(value)
              }}
              optionType="button"
              buttonStyle="solid">
              <Radio value="2560*800">2560*800</Radio>
              <Radio value="800*500">800*500</Radio>
              <Radio value="750*500">750*500</Radio>
              <Radio value="750*400">750*400</Radio>
              <Radio value="750*320">750*320</Radio>
              <Radio value="615*520">615*520</Radio>
              <Radio value="600*800">600*800</Radio>
              <Radio value="600*550">600*550</Radio>
              <Radio value="600*455">600*455</Radio>
              <Radio value="600*450">600*450</Radio>
              <Radio value="200*200">200*200</Radio>
              <Radio value="100*100">自定义比例</Radio>
            </Radio.Group>
            {/* <Switch checkedChildren='自定比例' unCheckedChildren='常用比例' /> */}
            <Space.Compact>
              <Input
                disabled={disableEditRatio}
                prefix="宽度"
                suffix="px"
                placeholder="缩放宽度"
                value={resizeWidth}
                onChange={({ target: { value } }) => {
                  setResizeWidth(value)
                }}
              />
              <Input
                disabled={disableEditRatio}
                prefix="高度"
                suffix="px"
                placeholder="缩放高度"
                value={resizeHeight}
                onChange={({ target: { value } }) => {
                  setResizeHeight(value)
                }}
                onPressEnter={() => {
                  setupRatio()
                }}
              />
              <Button
                disabled={disableEditRatio}
                type="primary"
                onClick={() => {
                  setupRatio()
                }}>
                确定
              </Button>
            </Space.Compact>
            <Button onClick={() => crop()}>完成</Button>
            <Divider plain orientation="left">
              HTML 代码
            </Divider>
            <Input.TextArea rows={8} value={cropResult} />
          </Space>
        </Col>
        <Col span={6}>
          <List
            header={<div>已裁剪过的图片</div>}
            bordered
            dataSource={croppedImageList}
            renderItem={(cropImage) => (
              <List.Item key={cropImage.PII_Location}>
                <Space direction="vertical">
                  <img
                    onClick={() => {
                      const imageHtml = generateHtml(cropImage, website)
                      navigator.clipboard.writeText(imageHtml)
                      setCropResult(imageHtml)
                    }}
                    style={{
                      cursor: 'pointer',
                      width: '100%',
                      height: '200px',
                      objectFit: 'scale-down',
                      objectPosition: 'center',
                    }}
                    src={
                      'https://p9axztuwd7x8a7.mycht.cn/service-fileServer' +
                      cropImage.PII_Location +
                      cropImage.PII_FileName
                    }></img>
                  <List.Item.Meta
                    title={
                      <a
                        target="_blank"
                        href={
                          'https://p9axztuwd7x8a7.mycht.cn/service-fileServer' +
                          cropImage.PII_Location +
                          cropImage.PII_FileName
                        }
                        rel="noreferrer">
                        {cropImage.PII_Width + '*' + cropImage.PII_Height}
                      </a>
                    }
                    description={cropImage.PII_ShortIntro}
                  />
                </Space>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </Spin>
  )
}

export default ImageCrop
