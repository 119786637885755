import { useAuthStore } from '@/stores/useAuthStore'
import { useMediaStore } from '@/stores/useMediaStore'
import { CloudUploadOutlined, DeleteOutlined, PlusOutlined, ReloadOutlined, SyncOutlined } from '@ant-design/icons'
import {
  Alert,
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  Progress,
  Radio,
  Result,
  Row,
  Select,
  Space,
  Spin,
  Upload,
} from 'antd'
import React, { useEffect, useState } from 'react'

function ImageUpload() {
  //store states
  const userId = useAuthStore((s) => s.userId)
  const imageUploadList = useMediaStore((s) => s.imageUploadList)
  const uploadPercent = useMediaStore((s) => s.uploadPercent)
  const requiredAlert = useMediaStore((s) => s.requiredAlert)
  const startUpload = useMediaStore((s) => s.startUpload)
  const syncProperties = useMediaStore((s) => s.syncProperties)
  const continueUpload = useMediaStore((s) => s.continueUpload)
  const initImageList = useMediaStore((s) => s.initImageList)
  const fetchOptionList = useMediaStore((s) => s.fetchOptionList)
  const updateImageProperty = useMediaStore((s) => s.updateImageProperty)
  const fetchCityList = useMediaStore((s) => s.fetchCityList)
  const removeUploadImage = useMediaStore((s) => s.removeUploadImage)
  //component states
  const [countrySelectOptions, setCountrySelectOptions] = useState([])
  const [citySelectOptions, setCitySelectOptions] = useState([])
  const [photographerSelectOptions, setPhotographerSelectOptions] = useState([])
  const [tagSelectOptions, setTagOptions] = useState([])

  useEffect(() => {
    fetchOptionList().then((result) => {
      setCountrySelectOptions(result.countryOptionList)
      setCitySelectOptions(result.cityOptionList)
      setPhotographerSelectOptions(result.photographerOptionList)
      setTagOptions(result.tagOptionList)
    })
  }, [])

  useEffect(() => {
    imageUploadList.map((imageHolder) => {
      const reader = new FileReader()
      reader.addEventListener('load', (event) => {
        const imageEle = document.getElementById(imageHolder.image_file.uid)
        imageEle.src = event.target.result
      })
      reader.readAsDataURL(imageHolder.image_file)
    })
  }, [imageUploadList])

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>
        添加图片<span style={{ fontWeight: 'bold' }}>(建议上传 jpg 图片)</span>
      </div>
    </div>
  )

  function renderImagePropertyEditor(imageHolder) {
    return (
      <Col span={21}>
        <Row gutter={[16, 16]} justify="start" align="middle" data-desc="first row">
          <Col span={4}>
            <Space direction="vertical" style={{ display: 'flex' }}>
              <label>
                国家<sup style={{ color: '#ff4d4f' }}>*</sup>
              </label>
              <Select
                style={{ width: '100%' }}
                showSearch
                value={[imageHolder.country]}
                placeholder="国家"
                onChange={(value) => {
                  updateImageProperty(imageHolder.image_file.uid, 'country', value)
                  fetchCityList(value).then((result) => {
                    setCitySelectOptions(result)
                  })
                }}
                onSearch={(value) => {}}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={countrySelectOptions}
              />
            </Space>
          </Col>
          <Col span={4}>
            <Space direction="vertical" style={{ display: 'flex' }}>
              <label>
                城市<sup style={{ color: '#ff4d4f' }}>*</sup>
              </label>
              <Select
                style={{ width: '100%' }}
                placeholder="城市"
                value={[imageHolder.city]}
                showSearch
                onChange={(value) => {
                  updateImageProperty(imageHolder.image_file.uid, 'city', value)
                }}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={citySelectOptions}
              />
            </Space>
          </Col>
          <Col span={8}>
            <Space direction="vertical" style={{ display: 'flex' }}>
              <label>
                景点,体验,名称(中文)<sup style={{ color: '#ff4d4f' }}>*</sup>
              </label>
              <Input
                placeholder="景点,体验,名称(中文)"
                value={imageHolder.description_zh}
                onChange={(e) => {
                  updateImageProperty(imageHolder.image_file.uid, 'description_zh', e.target.value)
                }}
              />
            </Space>
          </Col>
          <Col span={8}>
            <Space direction="vertical" style={{ display: 'flex' }}>
              <label>
                景点,体验,名称(英文)<sup style={{ color: '#ff4d4f' }}>*</sup>
              </label>
              <Input
                placeholder="景点,体验,名称(英文)"
                value={imageHolder.description_en}
                onChange={(e) => {
                  updateImageProperty(imageHolder.image_file.uid, 'description_en', e.target.value)
                }}
              />
            </Space>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16} justify="start" data-desc="second row">
          <Col span={4}>
            <Space direction="vertical" style={{ display: 'flex' }}>
              <label>
                摄影师<sup style={{ color: '#ff4d4f' }}>*</sup>
              </label>
              <AutoComplete
                style={{ width: '100%' }}
                options={photographerSelectOptions}
                value={imageHolder.photographer}
                placeholder="搜索或新增"
                onChange={(value) => {
                  updateImageProperty(imageHolder.image_file.uid, 'photographer', value)
                }}
                filterOption={(inputValue, option) =>
                  option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical" style={{ display: 'flex' }}>
              <label>
                版权<sup style={{ color: '#ff4d4f' }}>*</sup>
              </label>
              <Radio.Group
                options={[
                  { label: '使用权', value: '1' },
                  { label: '全版权', value: '5' },
                  { label: '无版权酒店图片', value: '8' },
                ]}
                value={imageHolder.copyright}
                onChange={({ target: { value } }) => {
                  updateImageProperty(imageHolder.image_file.uid, 'copyright', value)
                }}
                optionType="button"
                buttonStyle="solid"
              />
            </Space>
          </Col>
          <Col span={4}>
            <Space direction="vertical" style={{ display: 'flex' }}>
              <label>
                星级<sup style={{ color: '#ff4d4f' }}>*</sup>
              </label>
              <Radio.Group
                options={[
                  { label: '五星', value: '5' },
                  { label: '四星', value: '4' },
                  { label: '三星', value: '3' },
                ]}
                value={imageHolder.star}
                onChange={({ target: { value } }) => {
                  updateImageProperty(imageHolder.image_file.uid, 'star', value)
                }}
                optionType="button"
                buttonStyle="solid"
              />
            </Space>
          </Col>
          <Col span={5}>
            <Space direction="vertical" style={{ display: 'flex' }}>
              <label>标签</label>
              <Checkbox.Group
                options={tagSelectOptions}
                value={imageHolder.labelValues}
                onChange={(list) => {
                  updateImageProperty(imageHolder.image_file.uid, 'labelValues', list)
                }}
              />
            </Space>
          </Col>
          <Col span={5}>
            <Button icon={<DeleteOutlined />} onClick={() => removeUploadImage(imageHolder.image_file.uid)}>
              删除
            </Button>
          </Col>
        </Row>
      </Col>
    )
  }

  function imageUploaderRender(imageHolder, index) {
    return (
      <div key={'div_' + imageHolder.image_file.uid}>
        <Divider plain orientation="left" key={'Divider_' + imageHolder.image_file.uid}>
          {imageHolder.image_file.name}
        </Divider>
        <Row gutter={[16, 16]} justify="start" align="middle" key={'Row_' + imageHolder.image_file.uid}>
          <Col span={3}>
            <img
              style={{ maxWidth: '100%', maxHeight: '200px' }}
              alt={imageHolder.image_file.name}
              id={imageHolder.image_file.uid}
            />
          </Col>
          {imageHolder.success ? (
            <Col span={3}>
              <Result status="success" title="上传成功"></Result>
            </Col>
          ) : (
            renderImagePropertyEditor(imageHolder)
          )}
        </Row>
      </div>
    )
  }

  function renderToolbar() {
    const uploadSpinning = uploadPercent !== 100 && uploadPercent > 0
    return (
      <Spin spinning={uploadSpinning} delay={500}>
        <Row gutter={[16, 16]} justify="start" align="middle">
          <Col span={24}>
            <Space>
              <Progress size={20} percent={uploadPercent} steps={imageUploadList.length} />
              <Button icon={<CloudUploadOutlined />} onClick={() => startUpload(userId)}>
                上传
              </Button>
              <Button icon={<SyncOutlined />} onClick={() => syncProperties()}>
                同步所有信息
              </Button>
              <Button icon={<ReloadOutlined />} onClick={() => continueUpload()}>
                重置
              </Button>
            </Space>
          </Col>
        </Row>
      </Spin>
    )
  }

  return (
    <>
      <Upload
        key="imageUploader"
        multiple={true}
        listType="picture-card"
        showUploadList={false}
        beforeUpload={(file, fileList) => {
          initImageList(fileList)
          return false
        }}>
        {imageUploadList.length > 0 ? null : uploadButton}
      </Upload>

      {requiredAlert ? (
        <Alert
          message="上传错误"
          description="带星号(*)为必填项目，请补充完整内容。"
          style={{ margin: '0 0 16px 0' }}
          type="error"
          banner
          showIcon
        />
      ) : null}
      {imageUploadList.length > 0 ? renderToolbar() : null}
      {imageUploadList.length > 0 ? imageUploadList.map(imageUploaderRender) : null}
    </>
  )
}

export default ImageUpload
