import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import '@/assets/cropper.css'
import { useAuthStore } from '../stores/useAuthStore'

function Authorise() {
  const navigate = useNavigate()
  const { userId, website } = useParams()
  const setup = useAuthStore((s) => s.setup)
  setup(userId, website)

  useEffect(() => {
    navigate('/')
  }, [])
}

export default Authorise
