export default () => {
  /**
   * 清除sessin
   * @return void
   */
  function clearSession() {
    if (window.sessionStorage) {
      const sessionStorage = window.sessionStorage
      sessionStorage.clear()
    } else {
      console.error('browser not support sessionStorage!')
    }
  }

  /**
   * 获取session
   * @param key 缓存的KEY
   */
  function getSession(key) {
    if (window.sessionStorage) {
      const sessionStorage = window.sessionStorage
      return sessionStorage.getItem(key)
    } else {
      console.error('browser not support sessionStorage!')
      return null
    }
  }

  /**
   * 设置session
   * @param key 缓存的KEY
   * @param value 缓存数据
   */
  function putSession(key, value) {
    if (window.sessionStorage) {
      const sessionStorage = window.sessionStorage
      return sessionStorage.setItem(key, value)
    } else {
      console.error('browser not support sessionStorage!')
    }
  }

  return {
    clearSession,
    getSession,
    putSession,
  }
}
