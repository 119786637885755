import App from '@/views/App.jsx'
import Authorise from '@/views/Authorise'
import ImageCrop from '@/views/ImageCrop'
import ImageSearch from '@/views/ImageSearch'
import ImageUpload from '@/views/ImageUpload'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { isAuthentication } from './stores/useAuthStore'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    loader: () => isAuthentication(),
    children: [
      { index: true, element: <ImageSearch /> },
      { path: 'image/search', element: <ImageSearch /> },
      { path: 'image/upload', element: <ImageUpload /> },
      { path: 'image/crop', element: <ImageCrop /> },
      { path: 'authorise/:userId/:website', element: <Authorise /> },
    ],
  },
])

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} fallbackElement={() => <div>Loading...</div>} />
  </React.StrictMode>
  // <RouterProvider router={router} fallbackElement={() => <div>Loading...</div>} />
)
