import { create } from 'zustand'
import { prepareUrl, isNotEmpty, isEmpty } from '@/utils/commons'
import useStorage from '@/hooks/useStorage'
import { fetchJSON, postForm } from '@/utils/request'

export const useMediaStore = create((set, get) => ({
  selectedImage: null,
  imageSearchList: [],
  imageSearchListCahe: [],
  croppedImageList: [],
  requiredAlert: false,
  imageUploadList: [],
  uploadPercent: 0,
  setUpImageSearchList: () => {},
  setUpFullCopyRight: (searchlist) => {
    set((s) => ({ fullCopyrightList: searchlist }))
  },
  fetchOptionList: () => {
    const fetchCountryUrl = 'https://p9axztuwd7x8a7.mycht.cn/service-InfoSys/InfoSys/GetCountryList'
    const fetchPhotographerUrl = 'https://p9axztuwd7x8a7.mycht.cn/service-InfoSys/InfoSys/GetphotographerList'
    const fetchTagUrl = 'https://p9axztuwd7x8a7.mycht.cn/service-InfoSys/InfoSys/GetPhotoTagList'

    const countryPromise = fetchJSON(fetchCountryUrl).then((json) => {
      if (json.errcode == 0) {
        const countryOptionList = (json?.result ?? []).map((data, index) => {
          return {
            value: data.COI_SN,
            label: data.COI_Name,
          }
        })
        countryOptionList.unshift({ value: -1, label: '未知' })
        return countryOptionList
      } else {
        throw new Error(json.errmsg + ': ' + json.errcode)
      }
    })

    const cityPromise = get().fetchCityList('')

    const photographerPromise = fetchJSON(fetchPhotographerUrl).then((json) => {
      if (json.errcode == 0) {
        const photographerOptionList = (json?.result ?? []).map((data, index) => {
          return {
            value: data.U_Name,
            label: data.U_Name,
          }
        })
        return photographerOptionList
      } else {
        throw new Error(json.errmsg + ': ' + json.errcode)
      }
    })

    const tagPromise = fetchJSON(fetchTagUrl).then((json) => {
      if (json.errcode == 0) {
        const tagOptionList = (json?.result ?? []).map((data, index) => {
          return {
            value: data.Tag_SN,
            label: data.Tag_Name,
          }
        })
        return tagOptionList
      } else {
        throw new Error(json.errmsg + ': ' + json.errcode)
      }
    })

    return Promise.all([countryPromise, cityPromise, photographerPromise, tagPromise]).then((results) => {
      return {
        countryOptionList: results[0],
        cityOptionList: results[1],
        photographerOptionList: results[2],
        tagOptionList: results[3],
      }
    })
  },
  selectImage: (image) => {
    set({ selectedImage: image })
  },
  fetchImageList: (country, city, keyword, tags, star, copyright, type, userId) => {
    set({ imageSearchList: [] })

    if (isNotEmpty(keyword)) {
      const keywordListText = useStorage().get('KEYWORD_LIST')
      const keywordList = isEmpty(keywordListText) ? [] : keywordListText
      if (keywordList.indexOf(keyword) == -1) {
        keywordList.unshift(keyword)
      }
      if (keywordList.length > 8) {
        keywordList.splice(8, keywordList.length)
      }
      useStorage().set('KEYWORD_LIST', keywordList)
    }

    const formData = new FormData()
    formData.append('CountrySN', country)
    formData.append('citySN', city)
    formData.append('keyword', keyword)
    formData.append('tags', tags)
    formData.append('CopyRight', copyright)
    formData.append('star', star)
    formData.append('searchType', type)
    formData.append('user_id', userId)
    const postUrl = 'https://p9axztuwd7x8a7.mycht.cn/service-InfoSysSOA/search_image'

    return postForm(postUrl, formData).then((json) => {
      if (json.errcode == 0) {
        set({ imageSearchList: json.result })
      } else {
        throw new Error(json.errmsg + ': ' + json.errcode)
      }
    })
  },
  getKeywordHistory: () => {
    const keywordListText = useStorage().get('KEYWORD_LIST')
    const keywordList = isEmpty(keywordListText) ? [] : keywordListText
    return keywordList.map((keyword) => {
      return { value: keyword }
    })
  },
  fetchCityList: (countryId) => {
    const fetchCityUrl = prepareUrl('https://p9axztuwd7x8a7.mycht.cn/service-InfoSys/InfoSys/GetCityList')
      .append('coi_sn', countryId)
      .build()
    return fetchJSON(fetchCityUrl).then((json) => {
      if (json.errcode == 0) {
        const cityOptionList = (json?.result ?? []).map((data, index) => {
          return {
            value: data.CII_SN,
            label: data.CII_Name,
          }
        })
        cityOptionList.unshift({ value: -1, label: '未知' })
        return cityOptionList
      } else {
        throw new Error(json.errmsg + ': ' + json.errcode)
      }
    })
  },
  fetchCropImageList: (userId) => {
    const formData = new FormData()
    formData.append('PII_SN', get().selectedImage.PII_SN)
    formData.append('user_id', userId)
    const postUrl = 'https://p9axztuwd7x8a7.mycht.cn/service-InfoSysSOA/search_cutimage'

    return postForm(postUrl, formData).then((json) => {
      if (json.errcode == 0) {
        set({ croppedImageList: json.result })
      } else {
        throw new Error(json.errmsg + ': ' + json.errcode)
      }
    })
  },
  /* 待定 */
  toggleFavorite: (image, userId) => {
    image.isFavorite = !image.isFavorite
    set({ image })
    const formData = new FormData()
    formData.append('PII_SN', image.PII_SN)
    formData.append('user_id', userId)
    formData.append('addordelete', image.isFavorite ? 1 : 0)
    const postUrl = 'https://p9axztuwd7x8a7.mycht.cn/service-InfoSysSOA/favorite_image'

    return postForm(postUrl, formData).then((json) => {
      if (json.errcode == 0) {
        // json.result.PII_SN
      } else {
        throw new Error(json.errmsg + ': ' + json.errcode)
      }
    })
  },

  startUpload: (userId) => {
    for (let index = 0; index < get().imageUploadList.length; index++) {
      const element = get().imageUploadList[index]
      get().requiredAlert =
        element.country === null ||
        element.city === null ||
        element.description_zh === '' ||
        element.description_en === '' ||
        element.photographer === '' ||
        element.copyright === -1 ||
        element.star === -1

      if (get().requiredAlert) {
        console.error(element)
        break
      }
    }

    if (get().requiredAlert) return

    let successCount = 0
    set({ uploadPercent: 0 })
    const imageCount = get().imageUploadList.length
    const postUrl = 'https://p9axztuwd7x8a7.mycht.cn/service-InfoSysSOA/upload_image'

    get().imageUploadList.forEach((element, index) => {
      const formData = new FormData()
      formData.append('image_file', element.image_file)
      formData.append('image_uid', element.image_uid)
      formData.append('country', element.country)
      formData.append('city', element.city)
      formData.append('description_zh', element.description_zh)
      formData.append('description_en', element.description_en)
      formData.append('photographer', element.photographer)
      formData.append('copyright', element.copyright)
      formData.append('labels', element.labelValues.join(','))
      formData.append('user_id', userId)
      formData.append('star', element.star)

      return postForm(postUrl, formData).then((json) => {
        successCount++
        set({ uploadPercent: parseInt((successCount / imageCount) * 100) })
        if (json.errcode == 0) {
          const imageUploadList = get().imageUploadList
          for (var i = imageUploadList.length - 1; i >= 0; i--) {
            const current = imageUploadList[i]
            if (current.image_uid === json.result.image_uid) {
              current.success = true
              current.image_url = 'https://p9axztuwd7x8a7.mycht.cn/service-fileServer' + json.result.image_path
              break
            }
          }
          set({ imageUploadList: [...imageUploadList] })
        } else {
          throw new Error(json.errmsg + ': ' + json.errcode)
        }
      })
    })
  },

  startCrop: (cropData, resizeWidth, userId, website) => {
    const formData = new FormData()
    formData.append('filename', get().selectedImage.PII_Location + get().selectedImage.PII_FileName)
    formData.append('x', cropData.x)
    formData.append('y', cropData.y)
    formData.append('width', Math.trunc(cropData.width))
    formData.append('height', Math.trunc(cropData.height))
    formData.append('resize_width', resizeWidth)
    formData.append('user_id', userId)
    formData.append('webcode', website)
    const postUrl = 'https://p9axztuwd7x8a7.mycht.cn/service-InfoSysSOA/crop_image'

    return postForm(postUrl, formData).then((json) => {
      if (json.errcode == 0) {
        get().fetchCropImageList()
        const imageHtml = get().generateHtml(json.result, website)
        const cropResult = {
          imageHtml: imageHtml,
        }
        return cropResult
      } else {
        throw new Error(json.errmsg + ': ' + json.errcode)
      }
    })
  },

  getImageHost: (website) => {
    let imageHost = ''
    switch (website) {
      case 'ch':
        imageHost = 'https://images.chinahighlights.com'
        break
      case 'ah':
        imageHost = 'https://images.asiahighlights.com'
        break
      case 'gh':
        imageHost = 'https://images.globalhighlights.com'
        break
      case 'chinatravel':
        imageHost = 'https://images.chinatravel.com'
        break
      case 'sht':
        imageHost = 'https://images.shanghaihighlights.com'
        break
      case 'ts':
        imageHost = 'https://images.trainspread.com'
        break
      case 'mbj':
        imageHost = 'https://images.mybeijingchina.com'
        break
      default:
        imageHost = 'https://images.chinahighlights.com'
        break
    }
    return imageHost
  },
  generateHtml: (imageObj, website) => {
    const imageUrl = get().getImageHost(website) + imageObj.PII_Location + imageObj.PII_FileName
    let imageHtml = ''

    switch (website) {
      case 'ch':
        imageHtml =
          '<div class="infoimage"><img alt="' +
          imageObj.PII2_Intro +
          '" class="img-responsive" ' +
          ' width="' +
          imageObj.PII_Width +
          '" height="' +
          imageObj.PII_Height +
          '" src="' +
          imageUrl +
          '"><span class="infoimagetitle">' +
          imageObj.PII2_Intro +
          '</span></div>'
        break
      case 'ah':
        imageHtml =
          '<div class="infoimage"><img alt="' +
          imageObj.PII2_Intro +
          '" class="img-responsive" ' +
          ' width="' +
          imageObj.PII_Width +
          '" height="' +
          imageObj.PII_Height +
          '" src="' +
          imageUrl +
          '"><span class="photoTxt">' +
          imageObj.PII2_Intro +
          '</span></div>'
        break
      case 'gh':
        imageHtml =
          '<div class="infoimage"><img alt="' +
          imageObj.PII2_Intro +
          '" class="img-responsive" ' +
          ' width="' +
          imageObj.PII_Width +
          '" height="' +
          imageObj.PII_Height +
          '" src="' +
          imageUrl +
          '"><span class="infoimagetitle">' +
          imageObj.PII2_Intro +
          '</span></div>'
        break
      case 'chinatravel':
      case 'sht':
      case 'ts':
      case 'mbj':
        imageHtml =
          '<figure><img alt="' +
          imageObj.PII2_Intro +
          '" class="img-responsive" ' +
          ' width="' +
          imageObj.PII_Width +
          '" height="' +
          imageObj.PII_Height +
          '" src="' +
          imageUrl +
          '"></figure>'
        break
      default:
        imageHtml =
          '<img alt="' +
          imageObj.PII2_Intro +
          '" class="img-responsive" ' +
          ' width="' +
          imageObj.PII_Width +
          '" height="' +
          imageObj.PII_Height +
          '" src="' +
          imageUrl +
          '">'
        break
    }

    return imageHtml
  },
  useImage(imageId, userId, website) {
    const formData = new FormData()
    formData.append('PII_SN', imageId)
    formData.append('user_id', userId)
    formData.append('Webcode', website)
    const postUrl = 'https://p9axztuwd7x8a7.mycht.cn/service-InfoSysSOA/use_image'

    return postForm(postUrl, formData).then((json) => {
      if (json.errcode == 0) {
        console.info(json)
      } else {
        throw new Error(json.errmsg + ': ' + json.errcode)
      }
    })
  },
  continueUpload: () => {
    set({ imageUploadList: [], uploadPercent: -1 })
  },
  initImageList: (fileList) => {
    set({
      imageUploadList: fileList.map((file) => {
        return {
          image_file: file,
          image_uid: file.uid,
          country: null,
          city: null,
          description_zh: '',
          description_en: '',
          photographer: '',
          copyright: -1,
          labels: '',
          labelValues: [],
          user_id: -1,
          star: -1,
          success: false,
          image_url: '',
        }
      }),
    })
  },
  removeUploadImage: (fileUid) => {
    set({
      imageUploadList: get().imageUploadList.filter((image, index) => {
        return image.image_uid != fileUid
      }),
    })
  },
  syncProperties: () => {
    let imageUploadList = get().imageUploadList
    let helper = []
    if (imageUploadList.length > 1) {
      let first = imageUploadList[0]
      for (let index = 0; index < imageUploadList.length; index++) {
        const current = imageUploadList[index]
        current.country = first.country
        current.city = first.city
        current.description_zh = first.description_zh
        current.description_en = first.description_en
        current.photographer = first.photographer
        current.copyright = first.copyright
        current.labelValues = first.labelValues
        current.user_id = first.user_id
        current.star = first.star
        helper.push(current)
      }
      set({ imageUploadList: helper })
    }
  },
  updateImageProperty: (uid, name, value) => {
    const imageUploadList = get().imageUploadList
    for (var i = imageUploadList.length - 1; i >= 0; i--) {
      const current = imageUploadList[i]
      if (current.image_file.uid === uid) {
        current[name] = value
        break
      }
    }
    set({ imageUploadList: [...imageUploadList] })
  },
}))
