import { create } from 'zustand'
import useSession from '@/hooks/useSession'

const KEY_USER_ID = 'KEY_USER_ID'
const KEY_WEBSITE = 'KEY_WEBSITE'

export const useAuthStore = create((set) => ({
  // HT 账号 SN
  userId: null,
  // 从哪个网站打开，HTML 代码应用到哪个网站
  website: '',
  setup: (userId, website) => {
    set({ userId, website })
    useSession().putSession(KEY_USER_ID, userId)
    useSession().putSession(KEY_WEBSITE, website)
  },
}))

export const isAuthentication = () => {
  const userId = useSession().getSession(KEY_USER_ID)
  const website = useSession().getSession(KEY_WEBSITE)
  useAuthStore.setState({ userId, website })
  return null
}
